import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEye, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createRiset, deleteRiset, getRiset, getRisetCategory, updateRiset, uploadFile } from "../../service/riset.api";
import Swal from "sweetalert2";
import { ACTION_STATUS, RISET_TYPE, rupiahFormat, rupiahToInteger, tableCustomStyle } from "../../utils";


const currentDate = new Date().toISOString()
const defaultInput = {
    name: "",
    category_id: 0,
    title: "",
    type: "free",
    is_featured: false,
    is_recomended: false,
    created_at: currentDate,
    updated_at: currentDate,
    image: null
}
const Riset = () => {
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [action, setAction] = useState(0);
    const [input, setInput] = useState(defaultInput);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [meta, setMeta] = useState({
        totalItems: 0
    });
    const [image, setImage] = useState(null);

    useEffect(() => {
        fetchData();
    }, [page, limit]);

    useEffect(() => {
        getRisetCategory().then(res => setCategories(res.data));
    }, [])

    const fetchData = async () => {
        setLoading(true);
        console.log({ page, limit })
        try {
            const res = await getRiset(page, limit);
            setData(res.data.map(val => ({ ...val, category_id: val.riset_category.id, price: rupiahFormat(val.price), type: val.type.name })));
            setMeta(res.meta);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There something is wrong"
            })
        } finally {
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "No",
            selector: (row, index) => (limit * (page - 1)) + index + 1
        },
        {
            name: "File Image",
            selector: row => row.image,
            cell: row => {
                return <img style={{ width: "90px", height: "70px" }} src={row.image} alt="Course" />
            }
        },
        {
            name: "Name",
            selector: row => row.name
        },
        {
            name: "Category",
            cell: row => row.riset_category.name
        },
        {
            name: "Updated At",
            selector: row => moment(row.updated_at).format("dddd, d-MMMM-YYYY HH:m")
        },
        {
            name: "Actions",
            selector: (row) => row.id,
            cell: (row) => (
                <>
                    <Button
                        color="info"
                        size="sm"
                        className="mr-2"
                        onClick={() => { setAction(0); setInput(row); setModal(true) }}
                    >
                        <FaEye />
                    </Button>

                    <Button
                        color="warning"
                        size="sm"
                        className="mr-2"
                        onClick={() => { setAction(2); setInput(row); setModal(true); console.log(row) }}
                    >
                        <FaPencilAlt />
                    </Button>


                    <Button color="danger" size="sm" onClick={() => { onDel(row) }}>
                        <FaTrash />
                    </Button>
                </>
            ),
        },
    ];

    const onDel = (row) => {
        Swal.fire({
            title: `Delete Data`,
            text: `Delete ${row.name} category?`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "red"
        }).then(async () => {
            try {
                Swal.fire({
                    title: "Loading"
                })
                Swal.showLoading();
                await deleteRiset(row.id);
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Data saved"
                })
                fetchData();
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "There something is wrong"
                })
            }
        })
    }

    const uploadImage = async id => {
        try {
            const form = new FormData();
            form.append("files", image);
            form.append("riset_id", id);
            await uploadFile(form);
            setImage(null)
        } catch (error) {
            return Promise.reject("Error when uploading image");
        }
    }

    const onSubmit = async e => {
        e.preventDefault();
        let data = {};
        Object.keys(input).forEach(k => {
            if (!["created_at", "updated_at", "id", "riset_category", "image"].includes(k)) {
                data[k] = input[k]
            }
        });
        data['price'] = rupiahToInteger(data.price);
        Object.keys(input).forEach(k => {
            if (["price", "discount", "category_id"].includes(k)) {
                data[k] = parseInt(data[k]);
            }
        });
        Swal.fire({
            title: "Loading"
        })
        Swal.showLoading();
        try {
            let result = {};
            if (action == 1) {
                result = await createRiset(data);
            } else {
                result = await updateRiset(input.id, data);
            }
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Data saved"
            })
            if (image) {
                await uploadImage(result.data.id);
            }
            setModal(false);
            fetchData()
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There something is wrong"
            })
        }
    }

    const handleFileChange = (e) => {
        if (!e.target.files[0]) return;
        setImage(e.target.files[0]);
        const reader = new FileReader();
        reader.onloadend = () => setInput(prev => ({ ...prev, image: reader.result }));
        reader.readAsDataURL(e.target.files[0])
    };

    return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container className="mt-2" fluid>
                <Row>
                    <div className="col">
                        <Card className="bg-default shadow">
                            <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                <h3
                                    className="text-white mb-0"
                                >
                                    Riset
                                </h3>
                                <Button color="primary" onClick={() => { setAction(1); setInput(defaultInput); setModal(true) }}>
                                    <FaPlus className="mr-2" />
                                    Add
                                </Button>
                            </CardHeader>
                            <DataTable customStyles={tableCustomStyle} data={data} columns={columns} progressPending={loading} pagination paginationServer onChangeRowsPerPage={rows => setLimit(rows)} onChangePage={page => setPage(page)} paginationTotalRows={meta.totalItems} />
                        </Card>
                    </div>
                </Row>
            </Container>

            <Modal isOpen={modal} toggle={() => setModal(true)} size="xl">
                <form onSubmit={onSubmit}>
                    <ModalHeader toggle={() => setModal(false)}>{ACTION_STATUS[action]} Riset</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            {input.image && (
                                <img src={input.image} style={{ maxWidth: "100%", maxHeight: "300px", objectFit: "cover", display: "block" }} />
                            )}
                            <Label for="fileInput">Choose File</Label>
                            <Input
                                type="file"
                                id="fileInput"
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input
                                disabled={action == 0}
                                type="text"
                                defaultValue={input.name}
                                required
                                onChange={e => setInput(prev => ({ ...prev, name: e.target.value }))}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Title</Label>
                            <Input
                                disabled={action == 0}
                                type="text"
                                defaultValue={input.title}
                                required
                                onChange={e => setInput(prev => ({ ...prev, title: e.target.value }))}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Riset Category</Label>
                            <Input
                                disabled={action == 0}
                                type="select"
                                defaultValue={input.category_id}
                                onChange={e => setInput(prev => ({ ...prev, category_id: e.target.value }))}
                                required
                            >
                                <option value={0}>Select Option</option>
                                {categories.map((val, index) => (
                                    <option value={val.id} key={index}>{val.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Riset Type</Label>
                            <Input
                                disabled={action == 0}
                                type="select"
                                value={input.type}
                                onChange={e => setInput(prev => ({ ...prev, type: e.target.value }))}
                                required
                            >
                                {RISET_TYPE.map((val, index) => (
                                    <option value={val} key={index}>{val}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="descriptionInput">Short Description</Label>
                            <Input type="textarea" disabled={action == 0} value={input.short_desc} onChange={e => setInput(prev => ({ ...prev, short_desc: e.target.value }))} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="descriptionInput">Description</Label>
                            <CKEditor
                                config={{
                                    mediaEmbed: {
                                        previewsInData: true,
                                    },
                                }}
                                editor={ClassicEditor}
                                data={input.description}
                                onChange={(_, data) => {
                                    setInput(prev => ({ ...prev, description: data.getData() }))
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Price</Label>
                            <InputGroup>
                                <span className="input-group-text">Rp</span>
                                <Input
                                    disabled={action == 0}
                                    type="text"
                                    value={input.price}
                                    required
                                    onChange={e => setInput(prev => ({ ...prev, price: rupiahFormat(rupiahToInteger(e.target.value)) }))}
                                >
                                </Input>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>Discount</Label>
                            <Input
                                disabled={action == 0}
                                type="number"
                                min={0}
                                max={100}
                                defaultValue={input.discount}
                                required
                                onChange={e => setInput(prev => ({ ...prev, discount: e.target.value }))}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Stock On Hand</Label>
                            <Input
                                disabled={action == 0}
                                type="number"
                                min={0}
                                max={100}
                                defaultValue={input.stock_on_hand}
                                required
                                onChange={e => setInput(prev => ({ ...prev, stock_on_hand: e.target.value }))}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Permalink</Label>
                            <Input
                                disabled={action == 0}
                                type="text"
                                defaultValue={input.permalink}
                                required
                                onChange={e => setInput(prev => ({ ...prev, permalink: e.target.value }))}
                            >
                            </Input>
                        </FormGroup>
                        {input.type == "free" && (
                            <FormGroup>
                                <Label>Attachment</Label>
                                <Input
                                    disabled={action == 0}
                                    type="text"
                                    defaultValue={input.attachment}
                                    required
                                    onChange={e => setInput(prev => ({ ...prev, attachment: e.target.value }))}
                                />
                            </FormGroup>
                        )}
                        <FormGroup className="ml-4" >
                            <Input
                                type="checkbox"
                                checked={input.is_featured}
                                disabled={action == 0}
                                onChange={e => setInput(prev => ({ ...prev, is_featured: e.target.checked }))}
                            />
                            <Label>Is Featured</Label>
                        </FormGroup>
                        <FormGroup className="ml-4" >
                            <Input
                                type="checkbox"
                                checked={input.is_recomended}
                                disabled={action == 0}
                                onChange={e => setInput(prev => ({ ...prev, is_recomended: e.target.checked }))}
                            />
                            <Label>Is Recommended</Label>
                        </FormGroup>
                        <Row>
                            <Col xs="12" md="6">
                                <p className="m-0 p-0">Created At: {moment(input.created_at).format("dddd, d MMMM YYYY HH:m")}</p>
                            </Col>
                            <Col xs="12" md="6">
                                <p className="m-0 p-0">Updated At: {moment(input.updated_at).format("dddd, d MMMM YYYY HH:m")}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {action !== 0 && (
                            <Button type="submit" color="primary">
                                {ACTION_STATUS[action]} Riset
                            </Button>
                        )}
                        <Button color="secondary" onClick={() => setModal(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default Riset;