import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEye, FaPlus } from "react-icons/fa";
import { Button, Card, CardHeader, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Swal from "sweetalert2";
import { changePaymentState, getPurchaseHistory, confirmBasicPayment } from "../../service/order.api";
import { ACTION_STATUS, PAYMENT_STATE, rupiahFormat, tableCustomStyle } from "../../utils";
import { FaPencil } from "react-icons/fa6";
import { getRisetDetail } from "service/riset.api";


const defaultInput = {
    "appointment_url": null,
    "order_detail_id": 138,
    "order_number": "EEI20243415138",
    "product_name": "hehe",
    "price": 300000,
    "created_at": "15 Agustus 2024",
    "paid_at": null,
    "payment_method": null,
    "product_type": {
      "id": 7,
      "name": "Riset"
    },
    "status": "On Process",
    "payment_url": "https://app.sandbox.midtrans.com/snap/v4/redirection/c36778f5-1168-47ce-8f8e-dab61992ef48",
    "order": {
      "id": 138,
      "orderBy": "Export Expert",
      "orderNumber": "EEI20243415138",
      "itemCount": 1,
      "itemTotalAmount": 1000000,
      "totalDiscount": 70,
      "paymentTotal": 300000,
      "tax": 11,
      "notes": null,
      "addressLabel": null,
      "address": null,
      "mobilePhone": null,
      "country": null,
      "province": null,
      "city": null,
      "district": null,
      "subDistrict": null,
      "zipCode": null,
      "orderState": null,
      "shipmentTotal": null,
      "shippingType": null,
      "shippingServiceType": null,
      "shipmentState": null,
      "paymentType": null,
      "paymentState": "On Process",
      "completedAt": null,
      "canceledAt": null,
      "canceledReason": null,
      "snapUrl": "https://app.sandbox.midtrans.com/snap/v4/redirection/c36778f5-1168-47ce-8f8e-dab61992ef48",
      "createdAt": "2024-08-15T08:34:40.076Z",
      "updatedAt": "2024-08-15T08:34:40.000Z",
      "user": {
        "id": 77,
        "name": "Export Expert",
        "email": "master.exportexpert@gmail.com",
        "username": null,
        "password": null,
        "phonenumber": null,
        "registerType": 1,
        "isActive": true,
        "isVerified": false,
        "isDeleted": false,
        "createdAt": "2024-08-15T07:54:19.357Z",
        "updatedAt": "2024-08-15T07:54:19.357Z",
        "dateOfBirth": null,
        "gender": null,
        "domicile": null,
        "nik": null,
        "npwp": null
      }
    }
}
const choosePaymentColor = name => PAYMENT_STATE.filter(v => v.name == name)[0].color;
const OrderHistory = () => {
    const [data, setData] = useState([]);
    const [action, setAction] = useState(0);
    const [input, setInput] = useState(defaultInput);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [productType, setProductType] = useState("0");
    const [meta, setMeta] = useState({
        totalItems: 0
    });
    const [risetDetail, setRisetDetail] = useState({});
    const [paymentState, setPaymentState] = useState("");
    const [attachment, setAttachment] = useState("");

    useEffect(() => {
        fetchData();
    }, [page, limit, productType]);

    const fetchData = async () => {
        setLoading(true);
        try {
            let filter = "";
            if(productType !== "0") {
                filter = `filter.productType=$eq:${productType}`
            }
            const res = await getPurchaseHistory(page, limit, filter);
            setData(res.data);
            setMeta(res.meta);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There something is wrong"
            })
        } finally {
            setLoading(false);
        }
    }

    const editBtn = async (row) => {
        setAction(2);
        setInput(row);
        setModal(true);
        if(row.product_type.name == "Riset") {
            try {
                const res = await getRisetDetail(row.product_id);
                setRisetDetail(res);
                setPaymentState(row.order.paymentState);
            } catch (err) {
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "There something is wrong"
                })
            }
        }
    }

    const columns = [
        {
            name: "No",
            selector: (row, index) => (limit * (page - 1)) + index + 1
        },
        {
            name: "Product Name",
            selector: row => row.product_name
        },
        {
            name: "Order Number",
            selector: row => row.order.orderNumber
        },
        {
            name: "Order By",
            selector: row => row.order.orderBy
        },
        {
            name: "Created At",
            selector: row => moment(row.order.createdAt).format("dddd, d-MMMM-YYYY HH:m")
        },
        {
            name: "State",
            selector: (row) => row.order.paymentState,
            cell: (row) => (
                <>
                    <Button color={choosePaymentColor(row.order.paymentState)}>
                        {row.order.paymentState}
                    </Button>
                </>
            ),
        },
        {
            name: "Actions",
            selector: (row) => row.id,
            cell: (row) => (
                <>
                    <Button
                        color="info"
                        size="sm"
                        className="mr-2"
                        onClick={() => { setAction(0); setInput(row); setModal(true) }}
                    >
                        <FaEye />
                    </Button>
                    <Button
                        color="warning"
                        size="sm"
                        className="mr-2"
                        onClick={() => editBtn(row)}
                    >
                        <FaPencil />
                    </Button>
                </>
            ),
        },
    ];

    const onSubmit = async e => {
        e.preventDefault();
        if(risetDetail.type.id == 2 || risetDetail.type.id == 3) {
            try {
                Swal.fire({
                    text: "Loading"
                });
                Swal.showLoading();
                await changePaymentState(input.order.id, paymentState)
                Swal.close()
                setModal(false);
                fetchData()
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "There something is wrong"
                })
            }
        } else if (risetDetail.type?.id == 1 || input.order.paymentState == "Pending") {
            try {
                Swal.fire({
                    text: "Loading"
                });
                Swal.showLoading();
                await confirmBasicPayment(input.order.id, attachment)
                Swal.close()
                setModal(false);
                fetchData()
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "There something is wrong"
                })
            }
        }
    }

    return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container className="mt-2" fluid>
                <Row>
                    <div className="col">
                        <Card className="bg-default shadow">
                            <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-row justify-content-between align-items-center gap-5">
                                    <h3
                                        className="text-white mb-0 mr-2"
                                    >
                                        Riset
                                    </h3>
                                    <Input
                                        type="select"
                                        onChange={e => setProductType(e.target.value)}
                                        value={productType}
                                    >
                                        <option value="0">All</option>
                                        <option value="1">Course</option>
                                        <option value="2">Event</option>
                                        <option value="6">Expert</option>
                                        <option value="7">Riset</option>
                                    </Input>
                                </div>
                                <Button color="primary" onClick={() => { setAction(1); setInput(defaultInput); setModal(true) }}>
                                    <FaPlus className="mr-2" />
                                    Add
                                </Button>
                            </CardHeader>
                            <DataTable customStyles={tableCustomStyle} data={data} columns={columns} progressPending={loading} pagination paginationServer onChangeRowsPerPage={rows => setLimit(rows)} onChangePage={page => setPage(page)} paginationTotalRows={meta.totalItems} />
                        </Card>
                    </div>
                </Row>
            </Container>

            <Modal isOpen={modal} toggle={() => setModal(true)} size="xl">
                <form onSubmit={onSubmit}>
                    <ModalHeader toggle={() => setModal(false)}>{ACTION_STATUS[action]} Riset</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Product Name</Label>
                            <Input
                                disabled
                                type="text"
                                value={input.product_name}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Product Type</Label>
                            <Input
                                disabled
                                type="text"
                                value={input.product_type.name}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Order Number</Label>
                            <Input
                                disabled
                                type="text"
                                value={input.order_number}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Order Number</Label>
                            <Input
                                disabled
                                type="text"
                                value={input.order_number}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Payment Total</Label>
                            <Input
                                disabled
                                type="text"
                                value={`Rp. ${rupiahFormat(input.order.paymentTotal)}`}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Total Discount</Label>
                            <Input
                                disabled
                                type="text"
                                value={`${input.order.totalDiscount}%`}
                            >
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Payment Status</Label>
                            <Button color={choosePaymentColor(input.order.paymentState)} onClick={e => {e.preventDefault(); console.log(risetDetail)}} className="d-block">{input.order.paymentState}</Button>
                        </FormGroup>
                        {(risetDetail.type?.id == 2 || risetDetail.type?.id == 3) && (
                            <FormGroup>
                                <Label>Change Payment status</Label>
                                <Input
                                    type="select"
                                    defaultValue={paymentState}
                                    onChange={e => setPaymentState(e.target.value)}
                                    required
                                >
                                    <option value="Waiting Confirmation">Waiting Confirmation</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Canceled">Canceled</option>
                                    <option value="On Process">On Process</option>
                                    <option value="Pending">Pending</option>
                                </Input>
                            </FormGroup>
                        ) }
                        {(risetDetail.type?.id == 1 || input.order.paymentState == "Pending") && (
                            <FormGroup>
                            <Label>Attachment</Label>
                            <Input
                                type="text"
                                defaultValue={attachment}
                                required
                                onChange={e => setAttachment(e.target.value)}
                            >
                            </Input>
                        </FormGroup>
                        ) }
                    </ModalBody>
                    <ModalFooter>
                        {action !== 0 && (
                            <Button type="submit" color="primary">
                                {ACTION_STATUS[action]} Order
                            </Button>
                        )}
                        <Button color="secondary" onClick={() => setModal(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default OrderHistory;