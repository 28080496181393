import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Table,
    Button,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import EeiServices from "../../service/eei";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build"
import DataTable from "react-data-table-component";
import { tableCustomStyle } from "utils";

const Expert = (props) => {
    const [modal, setModal] = useState(false);
    const [typeId, setTypeId] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [experience, setExperience] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [salePrice, setSalePrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [sold, setSold] = useState(0);
    const [permalink, setPermalink] = useState("");
    const [isRecommended, setIsRecommended] = useState(true);
    const [isFeatured, setIsFeatured] = useState(true);
    const [expert, setExpert] = useState([]);
    const [productTypes, setProductType] = useState([]);
    const [expertCategories, setExpertCategory] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState('');
    const [images, setImages] = useState([]);
    const [deletedImages, removeImages] = useState([]);

    const toggleModal = () => setModal(!modal);
    const [editModal, setEditModal] = useState(false);
    const toggleEditModal = () => setEditModal(!editModal);

    const handleTypeIdChange = (e) => setTypeId(e.target.value);
    const handleCategoryIdChange = (e) => setCategoryId(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);
    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleExperienceChange = (e) => setExperience(e.target.value);
    const handleShortDescriptionChange = (e) => setShortDescription(e.target.value);
    const handlePriceChange = (e) => setPrice(e.target.value);
    const handleSalePriceChange = (e) => setSalePrice(e.target.value);
    const handleDiscountChange = (e) => setDiscount(e.target.value);
    const handleSoldChange = (e) => setSold(e.target.value);
    const handlePermalinkChange = (e) => setPermalink(e.target.value);
    const handleIsRecommendedChange = (e) => setIsRecommended(e.target.checked);
    const handleIsFeaturedChange = (e) => setIsFeatured(e.target.checked);

    const [editExpertData, setEditExpertData] = useState({
        id: null,
        type_id: "",
        category_id: "",
        name: "",
        title: "",
        experience: "",
        short_description: "",
        description: "",
        price: "",
        sale_price: "",
        discount: "",
        sold: "",
        permalink: "",
        is_recommended: "",
        is_featured: "",
    });

    const openEditModal = (item) => {
        setEditExpertData({
            id: item.id,
        });
        setTypeId(item.product_type.id)
        setCategoryId(item.expert_category.id)
        setName(item.name)
        setTitle(item.title)
        setExperience(item.experience)
        setShortDescription(item.short_description)
        setDescription(item.description)
        setPrice(item.price)
        setSalePrice(item.sale_price)
        setDiscount(item.discount)
        setSold(item.sold)
        setPermalink(item.permalink)
        setIsRecommended(item.isRecommended)
        setIsFeatured(item.isFeatured)
        const imgs = item.images ? item.images : ''
        setImages(imgs);
        setSelectedFiles(imgs)
        removeImages([])

        toggleEditModal();
    };

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [meta, setMeta] = useState({
        totalItems: 0
    })

    const handleEditSubmit = async () => {
        try {
            const { id } = editExpertData;

            await editExpert(id, typeId, categoryId, name, title, experience, shortDescription, description, price, salePrice, discount, sold, permalink, isRecommended, isFeatured);
            fetchData();
            toggleEditModal();
        } catch (error) {
            console.error('Failed to submit edited banner:', error);

            Swal.fire({
                icon: 'error',
                title: 'Failed to Save Changes',
                text: 'An error occurred while trying to save the changes.',
            });
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getActiveExpert(page, limit);
            setExpert(result.data);
            setMeta(result.meta);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch Expert data"
            })
        } finally {
            setLoading(false);
        }
    };

    const fetchType = async () => {
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getActiveProductType();
            setProductType(result.data);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch Type Expert data"
            })
        }
    }

    const fetchExpertCategories = async () => {
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getActiveExpertCategory();
            setExpertCategory(result.data);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch Expert Category"
            })
        }
    }

    useEffect(() => {
        fetchData();
        fetchType();
        fetchExpertCategories();
    }, [page, limit]);

    const editExpert = async (id, type_id, category_id, name, title, experience, short_description, description, price, sale_price, discount, sold, permalink, is_recommended, is_featured) => {
        try {
            const eeiServices = new EeiServices();

            await eeiServices.patchExpert(
                id, type_id,
                category_id,
                name,
                title,
                experience,
                short_description,
                description,
                Number(price),
                Number(sale_price),
                Number(discount),
                Number(sold),
                permalink,
                is_recommended,
                is_featured
            );

            await eeiServices.expertImage(id, selectedFiles);

            fetchData();
        } catch (error) {
            const message = error.message || `An error occurred while trying to edit the expert.`;

            Swal.fire({
                icon: 'error',
                title: 'Failed to Edit Expert',
                text: message,
            });
        }
    };

    const handleSubmit = async (ev) => {
        try {
            ev.preventDefault();
            const eeiServices = new EeiServices();

            const result = await eeiServices.ExpertService(
                typeId,
                categoryId,
                name, title,
                experience,
                shortDescription,
                description,
                price,
                salePrice,
                discount,
                sold,
                permalink,
                isRecommended,
                isFeatured
            );

            await eeiServices.expertImage(result.data.id, selectedFiles);
            console.log('Expert posted successfully:', result);

            Swal.fire({
                icon: 'success',
                title: 'Expert Posted!',
                text: 'The expert has been posted successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    toggleModal();
                }
            });

            setTypeId(0);
            setCategoryId(0);
            setName("");
            setTitle("");
            setExperience("");
            setShortDescription("");
            setDescription("");
            setPrice(0);
            setSalePrice(0);
            setDiscount(0);
            setSold(0);
            setPermalink("");
            setIsRecommended("");
            setIsFeatured("");
            fetchData();
        } catch (error) {
            console.error('Failed to post expert:', error);
            const message = error.message || `An error occurred while trying to post the expert.`;

            Swal.fire({
                icon: 'error',
                title: 'Failed to Post Expert',
                text: message,
            });
        }
    };

    const deleteExpert = async (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Delete Expert!',
            text: 'Are you sure to delete ?',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const eeiServices = new EeiServices();
                const result = await eeiServices.deleteExpert(id);
                console.log('Expert deleted successfully:', result);
                fetchData();
            }
        });
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFiles(file);
    }

    const removeImg = () => () => {
        setSelectedFiles('');
    }

    useEffect(() => {
        let price = editExpertData.price,
            discount = editExpertData.discount;
        const sale_price = (price - discount) < 0 ? 0 : (price - discount);
        setEditExpertData({
            ...editExpertData,
            sale_price: sale_price
        });

    }, [editExpertData.price, editExpertData.discount]);

    useEffect(() => {
        let _price = price,
            _discount = discount;
        const sale_price = (_price - _discount) < 0 ? 0 : (_price - _discount);
        setSalePrice(sale_price);

    }, [price, discount]);

    const columns = [
        {
            name: "File Image",
            selector: row => row.image,
            cell: row => {
                return <img style={{ width: "90px", height: "70px" }} src={row.images} alt="Course" />
            }
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Category',
            selector: row => row.expert_category.name,
        },
        {
            name: 'Actions',
            selector: row => row.id,
            cell: row => (
                <>
                    <Button color="info" size="sm" className="mr-2" onClick={() =>
                        openEditModal(row)
                    }>
                        <FaEdit />
                    </Button>

                    <Button color="danger" size="sm" onClick={() => deleteExpert(row.id)} >
                        <FaTrash />
                    </Button>
                </>
            )
        }
    ];

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">Expert Active</h3>
                                    <Button color="primary" onClick={toggleModal}>
                                        <FaPlus className="mr-2" />
                                        Add
                                    </Button>
                                </CardHeader>
                                <DataTable
                                    customStyles={tableCustomStyle}
                                    columns={columns}
                                    data={expert}
                                    progressPending={loading}
                                    paginationTotalRows={meta.totalItems}
                                    pagination
                                    onChangeRowsPerPage={rows => setLimit(rows)}
                                    paginationServer
                                    onChangePage={page => setPage(page)}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal} toggle={toggleModal} size="xl">
                <form onSubmit={handleSubmit} >
                    <ModalHeader toggle={toggleModal}>Add Expert</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="typeIdInput">Type Id</Label>
                            <Input
                                type="select"
                                id="typeIdInput"
                                defaultValue={''}
                                onChange={handleTypeIdChange}
                                required
                            >
                                <option value={''}>Choose a type</option>
                                {productTypes.map(prod =>
                                    <option key={prod.id} value={prod.id}>{prod.name}</option>
                                )}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="categoryIdInput">Category Id</Label>
                            <Input
                                type="select"
                                id="categoryIdInput"
                                defaultValue={''}
                                onChange={handleCategoryIdChange}
                                required
                            >
                                <option value={''}>Choose a category</option>
                                {expertCategories.map(exp =>
                                    <option key={exp.id} value={exp.id}>{exp.name}</option>
                                )}
                            </Input>
                        </FormGroup>

                        <FormGroup >
                            <Label for="fileInput">Choose Image</Label>
                            <input
                                className="d-block"
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                onChange={e => handleFileChange(e)}
                                required
                            />
                        </FormGroup>

                        <div className="col-12 d-flex align-items-center justify-content-center" >
                            {selectedFiles ?
                                <div className="position-relative preview-img mr-3"
                                    style={{
                                        width: '250px', height: '150px',
                                        background: 'url("' + (typeof selectedFiles === 'string' ? selectedFiles : URL.createObjectURL(selectedFiles)) + '")'
                                    }} >
                                    <button type="button" onClick={removeImg()} className="close position-absolute button-remove-img" >
                                        <span aria-hidden="true" className="text-white" >×</span>
                                    </button>
                                </div>
                                : ''}
                        </div>

                        <FormGroup>
                            <Label for="nameInput">Name</Label>
                            <Input
                                type="text"
                                id="name"
                                value={name}
                                onChange={handleNameChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="titleInput">Title</Label>
                            <Input
                                type="text"
                                id="titleInput"
                                value={title}
                                onChange={handleTitleChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="experienceInput">Experience</Label>
                            <Input
                                type="text"
                                id="experienceInput"
                                value={experience}
                                onChange={handleExperienceChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="shortDescriptionInput">Short Description</Label>
                            <Input
                                type="text"
                                id="shortDescriptionInput"
                                value={shortDescription}
                                onChange={handleShortDescriptionChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="descriptionInput">Description</Label>
                            <CKEditor
                                config={{
                                    mediaEmbed: {
                                        previewsInData: true,
                                    },
                                }}
                                editor={ClassicEditor}
                                data={description}
                                onChange={(_, data) => {
                                    setDescription(data.getData());
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="priceInput">Price</Label>
                            <Input
                                type="number"
                                id="priceInput"
                                value={price}
                                onChange={handlePriceChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="salePriceInput">Sale Price</Label>
                            <Input
                                type="number"
                                id="salePriceInput"
                                value={salePrice}
                                onChange={handleSalePriceChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="discountInput">Discount</Label>
                            <Input
                                type="number"
                                id="discountInput"
                                value={discount}
                                onChange={handleDiscountChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="soldInput">Sold</Label>
                            <Input
                                type="number"
                                id="soldInput"
                                value={sold}
                                onChange={handleSoldChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="permalinkInput">Permalink</Label>
                            <Input
                                type="text"
                                id="permalinkInput"
                                value={permalink}
                                onChange={handlePermalinkChange}
                            />
                        </FormGroup>

                        <FormGroup className="ml-4" >
                            <Input
                                type="checkbox"
                                id="isRecommendedInput"
                                value={isRecommended}
                                onChange={handleIsRecommendedChange}
                            />
                            <Label for="isRecommendedInput">Is Recommended</Label>
                        </FormGroup>

                        <FormGroup className="ml-4" >
                            <Input
                                type="checkbox"
                                id="isFeaturedInput"
                                value={isFeatured}
                                onChange={handleIsFeaturedChange}
                            />
                            <Label for="isFeaturedInput">Is Featured</Label>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" >
                            Post Expert
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={toggleEditModal} size="xl">
                <ModalHeader toggle={toggleEditModal}>Edit Expert</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="typeIdInput">Type Id</Label>
                        <Input
                            type="select"
                            id="typeIdInput"
                            defaultValue={typeId}
                            onChange={handleTypeIdChange}
                            required
                        >
                            <option value={''}>Choose a type</option>
                            {productTypes.map(prod =>
                                <option key={prod.id} value={prod.id}>{prod.name}</option>
                            )}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for="categoryIdInput">Category Id</Label>
                        <Input
                            type="select"
                            id="categoryIdInput"
                            defaultValue={categoryId}
                            onChange={handleCategoryIdChange}
                            required
                        >
                            <option value={''}>Choose a category</option>
                            {expertCategories.map(exp =>
                                <option key={exp.id} value={exp.id}>{exp.name}</option>
                            )}
                        </Input>
                    </FormGroup>

                    <FormGroup >
                        <Label for="fileInput">Choose Image</Label>
                        <input
                            className="d-block"
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            onChange={e => handleFileChange(e)}
                            required
                        />
                    </FormGroup>

                    <div className="col-12 d-flex align-items-center justify-content-center" >
                        {selectedFiles ?
                            <div className="position-relative preview-img mr-3"
                                style={{
                                    width: '250px', height: '150px',
                                    background: 'url("' + (typeof selectedFiles === 'string' ? selectedFiles : URL.createObjectURL(selectedFiles)) + '")'
                                }} >
                                <button type="button" onClick={removeImg()} className="close position-absolute button-remove-img" >
                                    <span aria-hidden="true" className="text-white" >×</span>
                                </button>
                            </div>
                            : ''}

                    </div>

                    <FormGroup>
                        <Label for="nameInput">Name</Label>
                        <Input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="titleInput">Title</Label>
                        <Input
                            type="text"
                            id="titleInput"
                            value={title}
                            onChange={handleTitleChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="experienceInput">Experience</Label>
                        <Input
                            type="text"
                            id="experienceInput"
                            value={experience}
                            onChange={handleExperienceChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="shortDescriptionInput">Short Description</Label>
                        <Input
                            type="text"
                            id="shortDescriptionInput"
                            value={shortDescription}
                            onChange={handleShortDescriptionChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="descriptionInput">Description</Label>
                        <CKEditor
                            config={{
                                mediaEmbed: {
                                    previewsInData: true,
                                },
                            }}
                            editor={ClassicEditor}
                            data={description}
                            onChange={(_, data) => {
                                setDescription(data.getData());
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="priceInput">Price</Label>
                        <Input
                            type="number"
                            id="priceInput"
                            value={price}
                            onChange={handlePriceChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="salePriceInput">Sale Price</Label>
                        <Input
                            type="number"
                            id="salePriceInput"
                            value={salePrice}
                            onChange={handleSalePriceChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="discountInput">Discount</Label>
                        <Input
                            type="number"
                            id="discountInput"
                            value={discount}
                            onChange={handleDiscountChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="soldInput">Sold</Label>
                        <Input
                            type="number"
                            id="soldInput"
                            value={sold}
                            onChange={handleSoldChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="permalinkInput">Permalink</Label>
                        <Input
                            type="text"
                            id="permalinkInput"
                            value={permalink}
                            onChange={handlePermalinkChange}
                        />
                    </FormGroup>

                    <FormGroup className="ml-4" >
                        <Input
                            type="checkbox"
                            id="isRecommendedInput"
                            checked={isRecommended}
                            onChange={handleIsRecommendedChange}
                        />
                        <Label for="isRecommendedInput">Is Recommended</Label>
                    </FormGroup>

                    <FormGroup className="ml-4" >
                        <Input
                            type="checkbox"
                            id="isFeaturedInput"
                            checked={isFeatured}
                            onChange={handleIsFeaturedChange}
                        />
                        <Label for="isFeaturedInput">Is Featured</Label>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleEditSubmit}>
                        Save Changes
                    </Button>
                    <Button color="secondary" onClick={toggleEditModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Expert;
