import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEye, FaPlus, FaTrash } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
    Label,
} from "reactstrap";
import Swal from "sweetalert2";
import { tableCustomStyle } from "utils";
import EeiServices from "../../service/eei";
import moment from "moment";
import { convertHtmlDate } from "utils";

const defaultInput = {
    expert: 0,
    day: new Date().toDateString(),
    gmeet_link: "",
    time: "00:00"
}

const Schedule = (props) => {
    const [modal, setModal] = useState(false);
    const [action, setAction] = useState(0);
    const [expert, setExpert] = useState([]);
    const [schedule, setSchedule] = useState([]);

    const fetchData = async () => {
        try {
            const eeiServices = new EeiServices();
            let result = await eeiServices.getActiveExpert();
            setExpert(result.data);

            result = await eeiServices.getSchedule();
            setSchedule(result.data);
        } catch (error) {
            console.error("Failed to fetch Expert data:", error);
        }
    };
    const [input, setInput] = useState(defaultInput);

    useEffect(() => {
        fetchData();
    }, []);

    const deleteExpert = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Delete Expert!",
            text: "Are you sure to delete ?",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const eeiServices = new EeiServices();
                const result = await eeiServices.deleteSchedule(id);
                console.log("Expert deleted successfully:", result);
                fetchData();
            }
        });
    };
    const columns = [
        {
            name: "Exporter",
            selector: (row) => row.expert.name,
        },
        {
            name: "Avaible Time",
            selector: (row) => row.available_time,
            cell: (row) => {
                return moment(row.available_time).format('MMMM Do YYYY, HH:mm:ss');
            },
        },
        {
            name: "Booked",
            selector: (row) => row.booked,
            cell: (row) => (row.booked == 1 ? "Booked" : "Not Booked"),
        },
        {
            name: "Actions",
            selector: (row) => row.id,
            cell: (row) => (
                <>
                    <Button
                        color="info"
                        size="sm"
                        className="mr-2"
                        onClick={() => {
                            const available_time = moment(row.available_time);
                            setInput({
                                expert: row.expert.id,
                                gmeet_link: row.gmeet_link,
                                day: available_time.toDate(),
                                time: available_time.format("HH:mm")
                            })
                            setModal(true);
                            setAction(0);
                        }}
                    >
                        <FaEye />
                    </Button>


                    <Button color="danger" size="sm" onClick={() => deleteExpert(row.id)}>
                        <FaTrash />
                    </Button>
                </>
            ),
        },
    ];

    const createData = () => {
        let date = new Date(`${input.day} ${input.time}`).toISOString();

        const data = {
            expert_id: Number(input.expert),
            available_time: date,
            gmeet_link: input.gmeet_link,
        };

        const eeiServices = new EeiServices();
        eeiServices.createSchedule(data).then(() => {
            setModal(false);
            fetchData();
        });
    };

    const setDate = (target) => {
        setInput((prevState) => ({
            ...prevState,
            day: new Date(target.value).toDateString(),
        }));
    };

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3
                                        className="text-white mb-0"
                                        onClick={() => console.log(schedule)}
                                    >
                                        Expert Schedule
                                    </h3>
                                    <Button color="primary" onClick={() => { 
                                        setInput(defaultInput)
                                        setModal(true);
                                        setAction(1);
                                     }}>
                                        <FaPlus className="mr-2" />
                                        Add
                                    </Button>
                                </CardHeader>
                                <DataTable
                                    customStyles={tableCustomStyle}
                                    columns={columns}
                                    data={schedule}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal} toggle={() => setModal(true)} size="xl">
                <ModalHeader toggle={() => setModal(false)}>Add Expert</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="categoryIdInput">Expert</Label>
                        <Input
                            disabled={action == 0}
                            type="select"
                            id="categoryIdInput"
                            defaultValue={input.expert}
                            onChange={({ target }) =>
                                setInput((prevState) => ({
                                    ...prevState,
                                    expert: target.value,
                                }))
                            }
                            required
                        >
                            <option value={0}>Choose a expert</option>
                            {expert.map((exp) => (
                                <option key={exp.id} value={exp.id}>
                                    {exp.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="permalinkInput">Gmeet Link</Label>
                        <Input
                            disabled={action == 0}
                            type="text"
                            id="permalinkInput"
                            value={input.gmeet_link}
                            onChange={({ target }) =>
                                setInput((prevState) => ({
                                    ...prevState,
                                    gmeet_link: target.value,
                                }))
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="day">Date</Label>
                        <Input
                            disabled={action == 0}
                            type="date"
                            id="day"
                            value={convertHtmlDate(input.day)}
                            onChange={({ target }) => setDate(target)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="hour">Time</Label>
                        <Input
                            disabled={action == 0}
                            type="time"
                            value={input.time}
                            onChange={e => setInput(prevState => ({
                                ...prevState,
                                time: e.target.value
                            }))}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    {action !== 0 && (
                        <Button disabled={action == 0} type="submit" color="primary" onClick={createData}>
                            Post Expert
                        </Button>
                    )}
                    <Button color="secondary" onClick={() => setModal(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Schedule;
