import { requestApi } from ".";

export const createEvent = data => {
    const form = new FormData();

    for (const [key, value] of Object.entries(data)) {
        form.append(key, value);
    }
    return requestApi.post("/event", form)
}

export const EditEvent = (id, data, oldData) => {
    const form = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if(value !== oldData[key] && key !== "files") {
            form.append(key, value);
        }
    }

    if(data.files) {
        form.append("files", data.files);
    }

    return requestApi.patch(`/event/${id}`, form)
}