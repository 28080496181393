export const tableCustomStyle = {
    rows: {
        style: {
            background: "#172b4d",
            color: "#FFF",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderColor: "#FFF"
        }
    },

    headRow: {
        style: {
            backgroundColor: "#1c345d",
            color: "#4d7bca",
            border: "1px solid #1f3a68"
        }
    },

    pagination: {
        style: {
            backgroundColor: "#1c345d",
            color: "#FFF",
            border: "1px solid #1f3a68"
        },
        pageButtonsStyle: {
            color: "#FFF",
        }
    }
};

export const ACTION_STATUS = ["View", "Add", "Edit"]

export const PAYMENT_STATE = [
    {
        state: "WAITING_CONFIRMATION",
        name: "Waiting Confirmation",
        color: "info"

    },
    {
        state: "COMPLETED",
        name: "Completed",
        color: "success"

    },
    {
        state: "CANCELED",
        name: "Canceled",
        color: "danger"

    },
    {
        state: "ON_PROCESS",
        name: "On Process",
        color: "primary"

    },
    {
        state: "PENDING",
        name: "Pending",
        color: "warning"

    },
];





export const rupiahFormat = (number) => {
    return new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        currency: 'IDR',
        minimumFractionDigits: 0
    }).format(number);
};

export const rupiahToInteger = (rupiah) => {
    // Menghapus semua karakter selain angka
    return parseInt(rupiah.replace(/[^0-9]/g, ''), 10);
}

export const RISET_TYPE = ["free", "basic", "advance", "enterprise"]

export const convertHtmlDate = (dateString) => {
    const inputDate = new Date(dateString);

    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
};