import {
    Button,
    Card, CardBody,
    CardHeader,
    Container,
    FormGroup, Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Table
} from "reactstrap";
import { FaEdit, FaPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import EeiServices from "../../service/eei";
import DataTable from "react-data-table-component";
import { tableCustomStyle } from "utils";

const ExpertCategory = () => {
    // region data
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        isEdit: false
    })

    const [expertCategory, setExpertCategory] = useState({
        id: 0,
        name: '',
        isActive: true
    })

    const [listExpertCategory, setListExpertCategory] = useState({
        data: [],
        statusCode: 0
    })
    // endregion data

    // region method
    async function getExpertCategory() {
        try {
            const eeiServices = new EeiServices();

            await Swal.fire({
                title: "Loading",
                async didOpen() {
                    Swal.showLoading();
                    setListExpertCategory(await eeiServices.getExpertCategory());
                    Swal.close();
                }
            });
        }
        catch (e) {
            await Swal.fire({
                icon: 'error',
                title: 'Failed to Get Expert Category',
                text: e.message,
            });
        }
    }

    function resetExpertCategory() {
        setExpertCategory({
            id: 0,
            name: '',
            isActive: true
        })
    }

    async function handleSubmit() {
        try {
            const eeiService = new EeiServices();
            await eeiService.postExpertCategory(expertCategory);

            await Swal.fire({
                icon: "success",
                title: "Success Add Expert Category"
            });

            resetExpertCategory();
            toggleModal()
            await getExpertCategory();
        }
        catch (e) {
            await Swal.fire({
                icon: 'error',
                title: 'Failed to Add Expert Category',
                text: e.message,
            });
        }
    }

    function toggleModal() {
        setModal({
            ...modal,
            isOpen: !modal.isOpen
        })
    }

    async function handleEdit() {
        try {
            const eeiService = new EeiServices();

            await Swal.fire({
                title: "Loading",
                async didOpen() {
                    Swal.showLoading();
                    await eeiService.editExpertCategory(expertCategory);
                    Swal.close();
                }
            });

            await Swal.fire({
                icon: "success",
                title: "Success Edit Expert Category"
            });

            resetExpertCategory();
            toggleModal();
            await getExpertCategory();
        }
        catch (e) {
            await Swal.fire({
                icon: 'error',
                title: 'Failed to Edit Expert Category',
                text: e.message,
            });
        }
    }
    // endregion method

    useEffect(() => {
        getExpertCategory()
            .then(() => console.log(Math.random()));
    }, []);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Status',
            selector: row => row.is_active ? "Active" : "Inactive",
        },
        {
            name: 'Actions',
            selector: row => row.id,
            cell: row => (
                <>
                    <Button
                        color={"info"}
                        size={"sm"}
                        className={"mr-2"}
                        onClick={() => {
                            setModal({
                                title: "Edit Expert Category",
                                isOpen: !modal.isOpen,
                                isEdit: true
                            })

                            setExpertCategory({
                                id: row.id,
                                name: row.name,
                                isActive: row.isActive
                            })
                        }}>
                        <FaEdit />
                    </Button>
                </>
            )
        }
    ];

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">
                                        Expert Category
                                    </h3>
                                    <Button color="primary" onClick={() => {
                                        setModal({
                                            isOpen: !modal.isOpen,
                                            title: "Add Expert Category",
                                            isEdit: false
                                        })
                                    }}>
                                        <FaPlus className="mr-2" />
                                        Add
                                    </Button>
                                </CardHeader>

                                <CardBody>
                                    <DataTable
                                        customStyles={tableCustomStyle}
                                        columns={columns}
                                        data={listExpertCategory.data}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={modal.isOpen}
                    size={"m"}
                    onClosed={resetExpertCategory}
                    toggle={toggleModal}
                >
                    <ModalHeader toggle={toggleModal}>
                        {modal.title}
                    </ModalHeader>

                    <ModalBody>
                        <FormGroup>
                            <Label for={"expert-category-name"}>
                                Name
                            </Label>

                            <Input
                                id={"expert-category-name"}
                                type={"text"}
                                value={expertCategory.name}
                                onChange={event => {
                                    setExpertCategory({
                                        ...expertCategory,
                                        name: event.target.value
                                    })
                                }} />
                        </FormGroup>

                        <FormGroup>
                            <Label for={"expert-category-is-active"}>
                                Is Active
                            </Label>

                            <Input
                                id={"expert-category-is-active"}
                                type={"select"}
                                defaultValue={expertCategory.isActive}
                                onChange={event => {
                                    setExpertCategory({
                                        ...expertCategory,
                                        isActive: event.target.value === "true"
                                    })
                                }}>
                                <option value={"true"}>True</option>
                                <option value={"false"}>False</option>
                            </Input>
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={() => {
                            modal.isEdit ? handleEdit() : handleSubmit()
                        }}>
                            {modal.isEdit ? "Update" : "Post"} Event
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
}

export default ExpertCategory;
