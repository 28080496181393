import { requestApi } from ".";

export const getRisetCategory = () => requestApi.get("/riset-category");

export const createRisetCategory = data => requestApi.post("/riset-category", data)

export const updateRisetCategory = (id, data) => requestApi.patch(`/riset-category/${id}`, data);

export const deleteRisetCategory = id => requestApi.delete(`/riset-category/${id}`);

export const getRiset = (page = 1, limit = 10) => requestApi.get(`/riset?page=${page}&limit=${limit}`);
export const getRisetDetail = (id) => requestApi.get(`/riset/${id}`);

export const createRiset = data => requestApi.post("/riset", data);

export const updateRiset = (id, data) => requestApi.patch(`/riset/${id}`, data);

export const deleteRiset = id => requestApi.delete(`/riset/${id}`)

export const uploadFile = data => requestApi.post(`riset-image`, data);