import axios from "axios";

const fetchWithJwt = async (url, options) => {
    const token = localStorage.getItem('accessToken');
    const headers = {
        'Content-Type': 'application/json',
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
        ...options,
        headers,
    });

    if (!response.ok) {
        // If the response is not OK, handle the error, e.g., redirect to the login page
        // You might also want to clear the local storage or perform other actions
        throw new Error(`Request failed with status: ${response.status}`);
    }

    return response.json();
};

const requestApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    }
});

requestApi.interceptors.response.use(res => res.data, err => {
    if(err.response.status == 401) {
        localStorage.clear("accessToken")
        window.location.href = "/login"
    }

    return Promise.reject(err);
})

export {
    requestApi,
    fetchWithJwt
}