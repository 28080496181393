import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./views/AuthContext.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import EeiServices from "service/eei.js";

const PrivateRoute = ({ element, ...props }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/auth/login" replace state={{ from: props.location }} />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route
          path="/admin/*"
          element={<PrivateRoute element={<AdminLayout />} />}
        />
        <Route path="/eei/*" element={<EeiServices />} />
        <Route path="*" element={<Navigate to="/admin/index" replace />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);