import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaPlus, FaTrash, FaEye, FaPencilAlt } from "react-icons/fa";
import { Button, Card, CardHeader, Container, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalFooter, ModalBody, Col } from "reactstrap";
import { tableCustomStyle } from "utils";
import { getRisetCategory } from "service/riset.api";
import moment from "moment";
import { ACTION_STATUS } from "utils";
import { createRisetCategory } from "service/riset.api";
import Swal from "sweetalert2";
import { updateRisetCategory } from "service/riset.api";
import { deleteRisetCategory } from "service/riset.api";

const currentDate = new Date().toISOString()
const defaultInput = {
    name: "",
    created_at: currentDate,
    updated_at: currentDate
}
const RisetCategory = () => {
    const [data, setData] = useState([]);
    const [action, setAction] = useState(0);
    const [input, setInput] = useState(defaultInput);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true)
        try {
            const res = await getRisetCategory();
            setData(res.data)
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There something is wrong"
            })
        } finally {
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "No",
            selector: (row, index) => index + 1
        },
        {
            name: "Name",
            selector: row => row.name
        },
        {
            name: "Created At",
            selector: row => moment(row.created_at).format("dddd, d-MMMM-YYYY HH:m")
        },
        {
            name: "Updated At",
            selector: row => moment(row.updated_at).format("dddd, d-MMMM-YYYY HH:m")
        },
        {
            name: "Actions",
            selector: (row) => row.id,
            cell: (row) => (
                <>
                    <Button
                        color="info"
                        size="sm"
                        className="mr-2"
                        onClick={() => {setAction(0); setInput(row); setModal(true)}}
                    >
                        <FaEye />
                    </Button>

                    <Button
                        color="warning"
                        size="sm"
                        className="mr-2"
                        onClick={() => {setAction(2); setInput(row); setModal(true)}}
                    >
                        <FaPencilAlt />
                    </Button>


                    <Button color="danger" size="sm" onClick={() => {onDel(row)}}>
                        <FaTrash />
                    </Button>
                </>
            ),
        },
    ];

    const onDel = (row) => {
        Swal.fire({
            title: `Delete Data`,
            text: `Delete ${row.name} category?`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "red"
        }).then(async () => {
            try {
                Swal.fire({
                    title: "Loading"
                })
                Swal.showLoading();
                await deleteRisetCategory(row.id);
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Data saved"
                })
                fetchData();
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "There something is wrong"
                })
            }
        })
    }

    const onSubmit = async e => {
        e.preventDefault();
        let data = {};
        Object.keys(input).forEach(k => {
          if(!["created_at", "updated_at", "id"].includes(k)) {
            data[k] = input[k]
          }
        });
        Swal.fire({
            title: "Loading"
        })
        Swal.showLoading();
        try {
            if(action == 1) {
                await createRisetCategory(data);
            } else {
                await updateRisetCategory(input.id, data);
            }
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Data saved"
            })
            fetchData()
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There something is wrong"
            })
        } finally {
            setModal(false)
        }
    }

    return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container className="mt-2" fluid>
                <Row>
                    <div className="col">
                        <Card className="bg-default shadow">
                            <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                <h3
                                    className="text-white mb-0"
                                >
                                    Riset Category
                                </h3>
                                <Button color="primary" onClick={() => {setAction(1); setInput(defaultInput); setModal(true)}}>
                                    <FaPlus className="mr-2" />
                                    Add
                                </Button>
                            </CardHeader>
                            <DataTable customStyles={tableCustomStyle} data={data} columns={columns} progressPending={loading} />
                        </Card>
                    </div>
                </Row>
            </Container>

            <Modal isOpen={modal} toggle={() => setModal(true)} size="xl">
                <form onSubmit={onSubmit}>
                    <ModalHeader toggle={() => setModal(false)}>{ACTION_STATUS[action]} Riset</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="categoryIdInput">Riset Category Name</Label>
                            <Input
                                disabled={action == 0}
                                type="text"
                                defaultValue={input.name}
                                required
                                onChange={e => setInput(prev => ({...prev, name: e.target.value}))}
                            >
                            </Input>
                        </FormGroup>
                        <Row>
                            <Col xs="12" md="6">
                                <p className="m-0 p-0">Created At: {moment(input.created_at).format("dddd, d MMMM YYYY HH:m")}</p>
                            </Col>
                            <Col xs="12" md="6">
                                <p className="m-0 p-0">Updated At: {moment(input.updated_at).format("dddd, d MMMM YYYY HH:m")}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {action !== 0 && (
                            <Button type="submit" color="primary">
                                {ACTION_STATUS[action]} Category
                            </Button>
                        )}
                        <Button color="secondary" onClick={() => setModal(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default RisetCategory;