import {fetchWithJwt} from ".";

export const AUTH_URL = process.env.REACT_APP_API_URL;
export const PRODUCT_TYPE = {
    COURSE: 1,
    EVENT: 2,
    CONSULTATION: 3,
    COMMERCE: 4,
    SHIPMENT: 5
}

const resetLogin = () => {
    resetLogin();
    localStorage.clear();
};

class EeiServices {
    // region auth
    async RegisterService(email, password, name) {
        try {
            const response = await fetch(`${AUTH_URL}/auth/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    name: name,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Login successful:", result);
            return result;
        } catch (error) {
            console.error("Login failed:", error);
            throw error;
        }
    }

    async LoginService(email, password) {
        try {
            const response = await fetchWithJwt(`${AUTH_URL}/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            console.log("Login successful:", response);
            return response;
        } catch (error) {
            console.error("Login failed:", error);
            throw error;
        }
    }
    // endregion auth

    // region Banner
    async BannerService(file, url, title, description, is_mobile = false) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const form = new FormData();
            form.append("url", url);
            form.append("title", title);
            form.append("description", description);
            form.append("file", file);
            form.append("is_mobile", is_mobile);
            const response = await fetch(`${AUTH_URL}/banner`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: form,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Post Banner successful:", result);
            return result;
        } catch (error) {
            console.error("Post Banner failed:", error);
            throw error;
        }
    }

    async getBanner(page = 1, limit = 10) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/banner?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            console.log("Response Status:", response.status);
            console.log("Response Headers:", response.headers);

            if (response.status === 401) {
                resetLogin();
            }

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const contentType = response.headers.get("Content-Type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Invalid content type. Expected JSON.");
            }

            const result = await response.json();
            console.log("Get Banner successful:", result);
            return result;
        } catch (error) {
            console.error("Get Banner failed:", error);
            throw error;
        }
    }

    async getActiveBanner() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/banner/active`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Active Banner successful:", result);
            return result;
        } catch (error) {
            console.error("Get Active Banner failed:", error);
            throw error;
        }
    }

    async patchBanner(id, file, url, title, description, isactive) {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }
        const form = new FormData();
        form.append("id", id);
        form.append("file", file);
        form.append("url", url);
        form.append("title", title);
        form.append("description", description);
        form.append("isActive", isactive);
        const response = await fetch(`${AUTH_URL}/banner/${id}`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: form,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
    }
    // endregion banner

    // region course category
    async postCourseCategory(courseCategory) {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const body = {
            name: courseCategory.name,
            isActive: courseCategory.isActive,
        };

        const response = await fetch(`${AUTH_URL}/course-category`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async getCourseCategory() {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(`${AUTH_URL}/course-category`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async editCourseCategory(courseCategory) {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(
            `${AUTH_URL}/course-category/${courseCategory.id}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: courseCategory.name,
                    is_active: courseCategory.isActive,
                }),
            },
        );

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }
    // endregion course category

    // region Course
    async CourseService(
        files,
        name,
        title,
        description,
        price,
        salePrice,
        measurement,
        discount,
        stockOnHand,
        permalink,
        recommended,
        isFeatured,
        typeId,
        categoryId,
    ) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const form = new FormData();
            form.append("files", files);
            form.append("name", name);
            form.append("title", title);
            form.append("description", description);
            form.append("price", price);
            form.append("salePrice", salePrice);
            form.append("measurement", measurement);
            form.append("discount", discount);
            form.append("stockOnHand", stockOnHand);
            form.append("permalink", permalink);
            form.append("isRecommended", recommended);
            form.append("isFeatured", isFeatured);
            form.append("typeId", typeId);
            form.append("categoryId", categoryId);
            const response = await fetch(`${AUTH_URL}/course`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: form,
            });
            const result = await response.json();

            if (!response.ok) {
                throw new Error(
                    result.message || `HTTP error! Status: ${response.status}`,
                );
            }

            console.log("Post Course successful:", result);
            return result;
        } catch (error) {
            console.error("Post Course failed:", error);
            throw error;
        }
    }

    async getCourse(page = 1, limit = 10) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/course?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 401) {
                resetLogin();
            }

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const contentType = response.headers.get("Content-Type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Invalid content type. Expected JSON.");
            }

            const result = await response.json();
            console.log("Get Course successful:", result);
            return result;
        } catch (error) {
            console.error("Get Course failed:", error);
            throw error;
        }
    }

    async getActiveCourse() {
        try {
            const response = await fetch(`${AUTH_URL}/course/active`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Active Course successful:", result);
            return result;
        } catch (error) {
            console.error("Get Active Course failed:", error);
            throw error;
        }
    }

    async patchCourse(
        id,
        files,
        name,
        title,
        description,
        price,
        salePrice,
        measurement,
        discount,
        stockOnHand,
        permalink,
        recommended,
        isFeatured,
        typeId,
        categoryId,
    ) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const form = new FormData();

            form.append("files", files);
            form.append("name", name);
            form.append("title", title);
            form.append("description", description);
            form.append("price", price);
            form.append("salePrice", salePrice);
            form.append("measurement", measurement);
            form.append("discount", discount);
            form.append("stockOnHand", stockOnHand);
            form.append("permalink", permalink);
            form.append("isRecommended", recommended.toString());
            form.append("isFeatured", isFeatured.toString());
            form.append("typeId", typeId);
            form.append("categoryId", categoryId);
            const response = await fetch(`${AUTH_URL}/course/${id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: form,
            });
            const result = await response.json();

            if (!response.ok) {
                throw new Error(
                    result.message || `HTTP error! Status: ${response.status}`,
                );
            }

            console.log("Post Course successful:", result);
            return result;
        } catch (error) {
            console.error("Post Course failed:", error);
            throw error;
        }
    }

    async deleteCourse(id) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const response = await fetch(`${AUTH_URL}/course/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            console.log("Course deleted successfully");
            return response.json();
        } catch (error) {
            console.error("Error deleting course:", error);
            throw error;
        }
    }
    // endregion course

    // region event category
    async editEventCategory(eventCategory) {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(
            `${AUTH_URL}/event-categories/${eventCategory.id}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: eventCategory.name,
                    isActive: eventCategory.isActive,
                }),
            },
        );

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async getEventCategory() {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(`${AUTH_URL}/event-categories`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async postEventCategory(eventCategory) {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const body = {
            name: eventCategory.name,
            isActive: eventCategory.isActive,
        };

        const response = await fetch(`${AUTH_URL}/event-categories`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async getActiveEventCategory() {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(`${AUTH_URL}/event-categories/active`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 401) {
            resetLogin();
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get("Content-Type");

        if (!contentType || !contentType.includes("application/json")) {
            throw new Error("Invalid content type. Expected JSON.");
        }

        return await response.json();
    }
    // endregion event category

    // region Event
    async EventService(eventRequest) {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const form = new FormData();

        for (const [key, value] of Object.entries(eventRequest)) {
            form.append(key, value);
        }
        const response = await fetch(`${AUTH_URL}/event`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: form,
        });

        if (!response.ok) {
            console.log(response.body);
            throw new Error(response.body.message);
        }

        return await response.json();
    }

    async getEvent(page = 1, limit = 10) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/event?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 401) {
                resetLogin();
            }

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const contentType = response.headers.get("Content-Type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Invalid content type. Expected JSON.");
            }

            const result = await response.json();
            console.log("Get Event successful:", result);
            return result;
        } catch (error) {
            console.error("Get Event failed:", error);
            throw error;
        }
    }

    async getActiveEvent() {
        try {
            const response = await fetch(`${AUTH_URL}/event/active`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Active Event successful:", result);
            return result;
        } catch (error) {
            console.error("Get Active Event failed:", error);
            throw error;
        }
    }

    async patchEvent(
        id,
        files,
        name,
        title,
        description,
        price,
        salePrice,
        measurement,
        discount,
        stockOnHand,
        sold,
        permalink,
        isRecommended,
        isFeatured,
        typeId,
        eventCategoriesId,
    ) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const form = new FormData();

            const sold = 0;
            const typeId = 0;

            if (files) {
                form.append("files", files);
            }
            form.append("name", name);
            form.append("title", title);
            form.append("description", description);
            form.append("price", price);
            form.append("salePrice", salePrice);
            form.append("measurement", measurement);
            form.append("discount", discount);
            form.append("stockOnHand", stockOnHand);
            form.append("sold", sold);
            form.append("permalink", permalink);
            form.append("isRecommended", isRecommended);
            form.append("isFeatured", isFeatured);
            form.append("typeId", typeId);
            const response = await fetch(`${AUTH_URL}/event/${id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: form,
            });

            const result = await response.json();

            if (!response.ok) {
                const message =
                    result.message || `HTTP error! Status: ${response.status}`;
                throw new Error(message);
            }

            console.log("Post Event successful:", result);
            return result;
        } catch (error) {
            console.error("Post Event failed:", error);
            throw error;
        }
    }

    async deleteEvent(id) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const response = await fetch(`${AUTH_URL}/event/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            console.log("Event deleted successfully");
            return response.json();
        } catch (error) {
            console.error("Error deleting Event:", error);
            throw error;
        }
    }
    // endregion event

    // region expert category
    async editExpertCategory(expertCategory) {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(
            `${AUTH_URL}/expert-category/${expertCategory.id}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: expertCategory.name,
                    is_active: expertCategory.isActive,
                }),
            },
        );

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async getExpertCategory() {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(`${AUTH_URL}/expert-category`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async postExpertCategory(expertCategory) {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const body = {
            name: expertCategory.name,
        };

        const response = await fetch(`${AUTH_URL}/expert-category`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        if (response.status === 401) {
            resetLogin();
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
            throw new Error(jsonResponse.message);
        }

        return jsonResponse;
    }

    async getActiveEventCategory() {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error("Access token not found in local storage");
        }

        const response = await fetch(`${AUTH_URL}/event-categories/active`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 401) {
            resetLogin();
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get("Content-Type");

        if (!contentType || !contentType.includes("application/json")) {
            throw new Error("Invalid content type. Expected JSON.");
        }

        return await response.json();
    }
    // endregion expert category

    // region Expert
    async ExpertService(
        type_id,
        category_id,
        name,
        title,
        experience,
        short_description,
        description,
        price,
        sale_price,
        discount,
        sold,
        permalink,
        is_recommended,
        is_featured,
    ) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/expert`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    type_id: Number(type_id),
                    category_id: Number(category_id),
                    name: name,
                    title: title,
                    experience: experience,
                    short_description: short_description,
                    description: description,
                    price: Number(price),
                    sale_price: Number(sale_price),
                    discount: Number(discount),
                    sold: Number(sold),
                    permalink: permalink,
                    is_featured: Boolean(is_featured),
                    is_recommended: Boolean(is_recommended),
                }),
            });

            const result = await response.json();

            if (!response.ok) {
                const message =
                    result.message || `HTTP error! Status: ${response.status}`;
                throw new Error(message);
            }

            console.log("Post Expert successful:", result);
            return result;
        } catch (error) {
            console.error("Post Expert failed:", error);
            throw error;
        }
    }

    async getExpert() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/expert`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 401) {
                resetLogin();
            }

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const contentType = response.headers.get("Content-Type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Invalid content type. Expected JSON.");
            }

            const result = await response.json();
            console.log("Get Expert successful:", result);
            return result;
        } catch (error) {
            console.error("Get Expert failed:", error);
            throw error;
        }
    }

    async getActiveExpert(page = 1, limit = 10) {
        try {
            const response = await fetch(`${AUTH_URL}/expert/active?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Active Expert successful:", result);
            return result;
        } catch (error) {
            console.error("Get Active Expert failed:", error);
            throw error;
        }
    }

    async patchExpert(
        id,
        type_id,
        category_id,
        name,
        title,
        experience,
        short_description,
        description,
        price,
        sale_price,
        discount,
        sold,
        permalink,
        is_recommended,
        is_featured,
    ) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const form = {
                type_id,
                category_id,
                name,
                title,
                experience,
                short_description,
                description,
                price,
                sale_price,
                discount,
                sold,
                permalink,
                is_recommended,
                is_featured,
            };
            const response = await fetch(`${AUTH_URL}/expert/${id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(form),
            });

            const result = await response.json();

            if (!response.ok) {
                const message =
                    result.message || `HTTP error! Status: ${response.status}`;
                throw new Error(message);
            }

            console.log("Post Event successful:", result);
            return result;
        } catch (error) {
            console.error("Post Event failed:", error);
            throw error;
        }
    }

    async deleteExpert(id) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const response = await fetch(`${AUTH_URL}/expert/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Delete Expert successful:", result);
            return result;
        } catch (error) {
            console.error("Delete Expert failed:", error);
            throw error;
        }
    }

    async expertImage(expert_id, files) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const formData = new FormData();
            formData.append("expert_id", expert_id);
            formData.append("files", files);

            const response = await fetch(`${AUTH_URL}/expert-image`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            const result = await response.json();

            if (!response.ok) {
                const message =
                    result.message || `HTTP error! Status: ${response.status}`;
                throw new Error(message);
            }

            console.log("Post Expert successful:", result);
            return result;
        } catch (error) {
            console.error("Post Expert failed:", error);
            throw error;
        }
    }

    async inActiveExpertImage(expert_id) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const response = await fetch(`${AUTH_URL}/expert-image/${expert_id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ is_active: false }),
            });

            const result = await response.json();

            if (!response.ok) {
                const message =
                    result.message || `HTTP error! Status: ${response.status}`;
                throw new Error(message);
            }

            console.log("Patch Expert successful:", result);
            return result;
        } catch (error) {
            console.error("Patch Expert failed:", error);
            throw error;
        }
    }
    // endregion expert

    // region expert category
    async getActiveExpertCategory() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/expert-category/active`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Active Expert category successful:", result);
            return result;
        } catch (error) {
            console.error("Get Active Expert category failed:", error);
            throw error;
        }
    }
    // endregion expert category

    // region Article
    async ArticleService({ file, article_category_id, title, description, url }) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const form = new FormData();
            form.append("title", title);
            form.append("article_category_id", article_category_id);
            form.append("file", file);
            form.append("description", description);
            form.append("url", url);
            const response = await fetch(`${AUTH_URL}/article`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: form,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Post Article successful:", result);
            return result;
        } catch (error) {
            console.error("Post Article failed:", error);
            throw error;
        }
    }

    async getArticle() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/article`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 401) {
                resetLogin();
            }

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const contentType = response.headers.get("Content-Type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Invalid content type. Expected JSON.");
            }

            const result = await response.json();
            console.log("Get Article successful:", result);
            return result;
        } catch (error) {
            console.error("Get Article failed:", error);
            throw error;
        }
    }

    async getArticleActive(page = 1, limit = 10) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/article/active?page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 401) {
                resetLogin();
            }

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const contentType = response.headers.get("Content-Type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Invalid content type. Expected JSON.");
            }

            const result = await response.json();
            console.log("Get Article successful:", result);
            return result;
        } catch (error) {
            console.error("Get Article failed:", error);
            throw error;
        }
    }

    async patchArticle(id, file, url, title, categoryId, description, isactive) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const form = new FormData();
            form.append("id", id);
            if (file) {
                form.append("file", file);
            }
            if (url) {
                form.append("url", url);
            }
            if (title) {
                form.append("title", title);
            }
            if (description) {
                form.append("description", description);
            }
            if (categoryId) {
                form.append("article_category_id", categoryId);
            }
            form.append("is_active", isactive);
            const response = await fetch(`${AUTH_URL}/article/${id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: form,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Post Article successful:", result);
            return result;
        } catch (error) {
            console.error("Post Article failed:", error);
            throw error;
        }
    }

    async deleteArticle(id) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const response = await fetch(`${AUTH_URL}/article/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Delete Article successful:", result);
            return result;
        } catch (error) {
            console.error("Delete Article failed:", error);
            throw error;
        }
    }
    // endregion article

    // region category article
    async getActiveArticleCategory() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/article-category/active`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Active Category successful:", result);
            return result;
        } catch (error) {
            console.error("Get Active Category failed:", error);
            throw error;
        }
    }
    // endregion article

    // region product type
    async getActiveProductType() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/product-type/active`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Active Product type successful:", result);
            return result;
        } catch (error) {
            console.error("Get Active Product type failed:", error);
            throw error;
        }
    }
    // endregion product type

    async getSchedule() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/expert-schedule/all`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Get Expert Schedule successful:", result);
            return result;
        } catch (error) {
            console.error("Get Expert Schedule failed:", error);
            throw error;
        }
    }

    async createSchedule(data) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }
            const response = await fetch(`${AUTH_URL}/expert-schedule`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("create sechedule successful:", result);
            return result;
        } catch (error) {
            console.error("Get Expert Schedule failed:", error);
            throw error;
        }
    }

    async deleteSchedule(id) {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) {
                throw new Error("Access token not found in local storage");
            }

            const response = await fetch(`${AUTH_URL}/expert-schedule/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            console.log("Course deleted successfully");
            return response.json();
        } catch (error) {
            console.error("Error deleting course:", error);
            throw error;
        }
    }
}

export default EeiServices;
