import { requestApi } from ".";

export const getOrderHistory = (page = 1, limit = 10) => requestApi.get(`/order/order-history?page=${page}&limit=${limit}`);

export const getPurchaseHistory = (page = 1, limit = 10, filter = "") => requestApi.get(`/order-detail/purchase-history?page=${page}&limit=${limit}&${filter}`)

export const changePaymentState = (order_id, state) => requestApi.post(`/order/change_state`, {
    order_id,
    state
})

export const confirmBasicPayment = (order_id, attachment) => requestApi.post(`/order/confirm_basic_riset`, {
    order_id,
    attachment
})